<template>
  <div>
  <div style="height:64px"></div>
  <v-carousel
    cycle
    height="auto"
    hide-delimiter-background
    hide-delimiters 
    touch
    :show-arrows="false"
    rtl="true"
    class="pb-0 mb-0"

  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      class="pb-0 mb-0"
      :src="item.src"
    >

    </v-carousel-item>
  </v-carousel>
    <v-main  class="grey lighten-3 mt-0 pt-0 edus">
      <v-container>
        <div style="background-color:#DEDEDE;border-radius:10px;" class="mt-5"> 
        <h1 class="ml-3" >인기 클래스</h1>
        <h3 class="ml-3 mb-3 grey--text">가장 인기 많은 클래스를 만나보세요!</h3>
        <v-row class="mb-8 ma-2" >
          <v-col

            cols="6"
          >
          <ClassCard class="hidden-md-and-down" :classInfo="classInfo" />
          <class-card-small class="hidden-lg-and-up" :classInfo="classInfo"/>                                                    
          </v-col>
          <v-col

            cols="6"
          >
          <ClassCard2 class="hidden-md-and-down" :classInfo="classInfo2"  />  
          <class-card-2-small class="hidden-lg-and-up" :classInfo="classInfo2"/>                                                  
          </v-col>
        </v-row>
        </div>
        <div style="background-color:#DEDEDE;border-radius:10px"> 
        <h1 class="mt-8 ml-3">작품 사진</h1>
        <v-row class="mb-16 ma-1" >
          <v-col

            cols="12"
            xs="12"
            sm="12"
            md="12"
            lg="12"
          >
          <MainImage/>
          </v-col>

        </v-row>
        </div>
        <!-- <h1 class="mt-12 justify-center">프로젝트</h1> -->
        <!-- <v-row >
          <v-col
            v-for="n in 8"
            :key="n"
            cols="12"
            xs="3"
            sm="5"
            md="4"
            lg="3"
          >

          </v-col>
        </v-row> -->
        <v-btn
            v-scroll="onScroll"
            dark
            elevation="0"
            fixed
            fab
            bottom
            x-large
            color="yellow"
            right
            @click="toTop"
            class="mb-15 mr-7 hidden-md-and-down"
          >
          <v-icon color="#572727">mdi-chat-plus </v-icon>
        </v-btn>
                <v-btn
            v-scroll="onScroll"
            dark
            elevation="0"
            fixed
            fab
            bottom
            x-large
            color="yellow"
            right
            @click="toTop"
            class="mb-15 mr-n3 hidden-lg-and-up"
          >
          <v-icon color="#572727">mdi-chat-plus </v-icon>
          </v-btn>
      </v-container>
    </v-main>
      <v-footer
    dark
    color="primary"
    class="edus"
  >
    <v-card
      flat
      width="1000000"
      class="primary white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
          @click="toInsta"
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <pre style="font-weight:800" >
WHATs LAB
대표자 : 심동현   사업자등록번호 : 827-08-01981  
사업자등록주소: 서울특별시 광진구 능동 236-20번지, 3층
이메일 : sdh@whatslab.co.kr
        </pre>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        2020 - {{ new Date().getFullYear() }} <strong>WHATs LAB</strong>
      </v-card-text>
    </v-card>
  </v-footer>
  </div>
  
</template>

<script>
  import ClassCard from '../components/Edu/ClassCard'
  import ClassCard2 from '../components/Edu/ClassCard2'
  import MainImage from '../components/MainImage.vue'
  import ClassCardSmall from '../components/Edu/ClassCardSmall.vue'
  import ClassCard2Small from '../components/Edu/ClassCardSmall.vue'
  export default {
    components:{
      ClassCard,
      ClassCard2,
      MainImage,
      ClassCardSmall,
      ClassCard2Small,
    },
    data () {
      return {
         icons: [
        'mdi-instagram',
      ],
        fab: false,
        colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
        classInfo:{
          imgSrc:'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/poster%2Fposter1.png?alt=media&token=c38eb462-a567-491e-87f2-c984078781d3',
          dcOn:false,
          title:'엔트리 기초 강좌',
          teacher:'송혜원, 심동현',
          rating:5,
          newClass:true,
        },
        classInfo2:{
          imgSrc:'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/poster%2Farduino.png?alt=media&token=f5cc03c7-5972-4b11-8da8-b3969db7c5a7',
          dcOn:false,
          title:'엔트리와 아두이노',
          teacher:'송혜원, 심동현',
          rating:5,
          newClass:true,
        },
        items: [
          {
            name:'강의알아보기',
            src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/carousel%2Fcarousel1.png?alt=media&token=a3491526-dd69-4ba7-9e92-bccb665989be',
          },
          {
            name:'강의알아보기2',
            src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/carousel%2Fbaner.png?alt=media&token=5d27fb9d-fe3a-47dc-9f24-6f585d86e4bb',
          },
          {
            name:'강의알아보기3',
            src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/carousel%2Fcarousel1.png?alt=media&token=a3491526-dd69-4ba7-9e92-bccb665989be',
          },
          {
            name:'강의알아보기4',
            src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/carousel%2Fbaner.png?alt=media&token=5d27fb9d-fe3a-47dc-9f24-6f585d86e4bb',
          },
        ],
      }
    },
    methods:{
      al(){
        alert("s")
      },
      onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 0;
    },
    toTop () {
      location.href="https://pf.kakao.com/_wxdlMs/chat"
    },
    toInsta(){
      location.href = "https://www.instagram.com/whats._lab/"
    }
    }
  }
</script>
<style >
@font-face {
    font-family: 'CookieRunOTF-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/CookieRunOTF-Bold00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.edus{
    font-family: 'CookieRunOTF-Bold' ;
}
</style>