import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import firebase from 'firebase'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDFqZe-ub7zU9mp1Y1Md2xhkToRIFoTh70",
  authDomain: "whatslab-67b1d.firebaseapp.com",
  databaseURL: "https://whatslab-67b1d-default-rtdb.firebaseio.com",
  projectId: "whatslab-67b1d",
  storageBucket: "whatslab-67b1d.appspot.com",
  messagingSenderId: "882020858338",
  appId: "1:882020858338:web:77a58a68eb56fa7ab1e1bb",
  measurementId: "G-ENTBDQLWMC"
};

firebase.initializeApp(firebaseConfig)
firebase.auth().onAuthStateChanged((fireU) => store.commit("setFireUser",fireU))
Vue.prototype.$firebase = firebase
Vue.use(Vuex)
new Vue({
  vuetify,
  store,
  router,

  render: h => h(App)
}).$mount('#app')
