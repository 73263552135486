<template>
    <v-app style="background-color:#eeeeee">
        <!-- <v-app-bar color='orange' app elevation="0" absolute >
            <v-app-bar-title style="margin-left:15%;cursor:pointer"  >
                
                <div @click="al" style="font-weight:800; font-size:x-large">WHATS LAB</div>
                
                </v-app-bar-title>

            <div @click="signWithGoogle" v-show="this.$store.state.fireUser===null" style="float:right;margin-right:15%;cursor:pointer;font-weight:700">로그인</div>
            <v-avatar  v-if="this.$store.state.fireUser!==null"  class="mr-3">
            <img
                :src="this.$store.state.fireUser.photoURL"
                alt="John"
            >
            </v-avatar>
            <v-app-bar-title v-if="this.$store.state.fireUser!==null" class="mr-5 subtitle-1">{{this.$store.state.fireUser.displayName}}님 안녕하세요.</v-app-bar-title>
            <div v-show="this.$store.state.fireUser!==null" @click="signOut" style="margin-right:15%;cursor:pointer;font-weight:700">로그아웃</div>
            
        </v-app-bar> -->
            <v-app-bar
      app
      color="primary"
      dark
      elevation="0"
      absolute
    >
      <div class="d-flex align-center ml-15">
        <v-img
          class="shrink"
          contain
          src="https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/aa.png?alt=media&token=67072eb0-7a2b-49f9-8a0e-8e21077c0dcd"
          transition="scale-transition"
          width="40"
        />

        <v-img
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="220"
          src="https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/logo2.png?alt=media&token=e8c244e5-8825-41a9-9229-c138b3787ed1"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

            <div @click="signWithGoogle" v-show="this.$store.state.fireUser===null" style="cursor:pointer;font-weight:700;font-size:x-large" class="mr-15 ">로그인</div>
            <v-avatar  v-if="this.$store.state.fireUser!==null"  class="mr-3">
            <img
                :src="this.$store.state.fireUser.photoURL"
                alt="John"
            >
            </v-avatar>
            <v-app-bar-title v-if="this.$store.state.fireUser!==null" class="mr-3 subtitle-1 hidden-sm-and-down" >{{this.$store.state.fireUser.displayName}}님 안녕하세요.</v-app-bar-title>
            <div v-show="this.$store.state.fireUser!==null" @click="signOut" style="cursor:pointer;font-weight:700;font-size:x-large" class="mr-15">로그아웃</div>
        </v-app-bar>
        <router-view />
        
    </v-app>
</template>

<script>
import firebase from 'firebase'
export default {
    data(){
        return {
            aaa:null
        }
    },
    methods:{
         async signWithGoogle() {

            var provider = new firebase.auth.GoogleAuthProvider();
            this.$firebase.auth().languageCode = "korean";
            this.$firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
            this.loading = true;
            try {
            const snapshot = await this.$firebase.auth().signInWithPopup(provider);
            this.$store.commit("setFireUser", snapshot.user);
            // firebase.firestore().collection("users").doc(this.$store.state.fireUser.uid).update({
            //     userName:this.$store.state.fireUser.displayName,
            //     email:this.$store.state.fireUser.email,
            // });
            firebase.database().ref('users/'+this.$store.state.fireUser.uid).update({
                userName:this.$store.state.fireUser.displayName,
                email:this.$store.state.fireUser.email
            })
            console.log(this.$store.state.fireUser)
            } finally {
            this.readData()
            this.loading = false;
            }
        },
        signOut(){
            this.$firebase.auth().signOut();
            this.$store.commit("setFireUser", null);
            location.reload()
        },
        readData(){
            firebase.database().ref('users/').child(this.$store.state.fireUser.uid)
            .get()
            .then((snp)=>{
                this.$store.commit("setUserState", snp.val());
            })
        },
        al(){
            this.$router.push('/')
        }
    },
}
</script>

<style >
@font-face {
    font-family: 'CookieRunOTF-Bold';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/CookieRunOTF-Bold00.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
.edus{
    font-family: 'CookieRunOTF-Bold' ;
}
</style>