<template>
    <v-card class="ma-3 pa-5" flat rounded="1" >
        <v-card-action >
            <v-progress-circular
            :value="80"
            width="3"
            color="deep-purple lighten-2"
            class="float-left ml-3 mt-2"
            >
            1
            </v-progress-circular>
            {{title}}  
            <v-btn
                class="ml-16 mt-3"
                icon
                @click="show = !show"
            >
                <v-icon class="purple--text lighten-2">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-action>
        <div style="margin-left:-130px" class="caption">{{complete}} / {{all}}</div>
        <v-expand-transition><div v-if="show">
            <v-list >
            <v-list-item-group
                v-model="selectedItem"
                class="pa-3 "
                color="purple"
            >
                <v-list-item
                v-for="(item, i) in items"
                :key="i"
                class="pl-n16"
                @click="$store.commit('setSrc',item.src)"
                >
                    {{item.text}}
                </v-list-item>

            </v-list-item-group>
            </v-list>
        </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    props:['title'],
    data(){
        return {
            show: false,
            complete:7,
            all:9,
            selectedItem: 0,
            items: [
                { text: '1. 코딩과 알고리즘', src: 'https://player.vimeo.com/video/536315919?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
                { text: '2. 엔트리를 알아보고 설치해보자', src: 'https://player.vimeo.com/video/536625030?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
                {text:'3. 자판기를 만들어보자',src:'https://player.vimeo.com/video/542666741?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'},
                {text:'4. 숫자게임을 만들어보자',src:"https://player.vimeo.com/video/548436990" },
                {text:'5. 요리조리 피하기 게임을 만들어보자',src:"https://player.vimeo.com/video/556929118?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},
            ],
        }
    },
    methods:{
        setSrc(a){
            alert(a)
        }
    }
}
</script>