<template>
  <v-hover class="class-card">
    <template v-slot:default="{ hover }">
      <v-card
        flat
        outlined
      >
        <v-img :src="classInfo.imgSrc" >
        
        <div v-show="classInfo.dcOn" class="float-right mt-3 dc mr-n2"><div class="ml-1 caption">{{classInfo.dc}}% 세일</div></div>
        </v-img>
        <v-card-title class="pa-3">
          <h1 class="title primary--text font-weight-black">
            {{classInfo.title}}
          </h1>
        </v-card-title>
        <v-card-subtitle>
          <div style="margin-top:10px;margin-bottom:10px">{{classInfo.teacher}} 강사</div>
          <v-row
            align="center"
            class="mx-0 ml-n1 pt-1"
          >

          </v-row>
        </v-card-subtitle>
        <v-card-text v-show="classInfo.dcOn">
          <div  :class="{'headline primary--text font-weight-bold':!classInfo.dcOn,
          'text-decoration-line-through subtitle-1 grey--text':classInfo.dcOn}">₩{{formatPrice(classInfo.price)}} </div>
          <div v-if="classInfo.dcOn" class="headline primary--text font-weight-bold mt-n1">₩{{formatPrice((100-classInfo.dc)*classInfo.price/100)}}</div>
          <div v-if="!classInfo.dcOn" class="headline white--text">a</div>
          <v-chip-group class="pa-0">
          <v-chip v-if="classInfo.dcOn" outlined color="red" x-small class="mt-1 pa-1">세일중</v-chip>
          <v-chip v-if="classInfo.newClass" outlined color="primary" x-small class="mt-1 pa-1">새강의</v-chip>
          <v-chip v-if="classInfo.newClass" outlined color="green" x-small class="mt-1 pa-1">교구제공</v-chip>
          </v-chip-group>
        </v-card-text>
        <v-fade-transition>
          <div  @click="toGo()">
          <v-overlay 
            v-if="hover"
            absolute
            opacity="0"
            color="primary"
            style="cursor:pointer"
            
          >
          <v-card-text >
            <div class="title ">{{classInfo.content}}</div>
            
          </v-card-text>
          </v-overlay>
          
          </div>
        </v-fade-transition>
      </v-card>
      
    </template>
  </v-hover>

</template>

<script>
  import firebase from 'firebase'
  export default {
    data: () => ({
      overlay: false,

    }),
    methods:{
      formatPrice(value) {
          let val = (value/1)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      toGo(){
      if(this.$store.state.fireUser!==null && this.$store.state.userState.class1===true){
            this.$router.push('/video')
        }else if(this.$store.state.fireUser!==null && this.$store.state.userState.class1===undefined){
            if(confirm("수강신청을 하시겠습니까?")){
            firebase.database().ref('users/'+this.$store.state.fireUser.uid).update({
                class1:false
            })
                alert('수강신청을 완료했습니다.')
                this.reLoad()
            }else{
                alert("수강신청을 취소했습니다.")
            }
        }else if(this.$store.state.fireUser!==null && this.$store.state.userState.class1!==true){
            alert('관리자가 확인중입니다. 다시로그인을 시도해 주세요.')
             this.reLoad()
        }else{
            alert('로그인이 필요한 서비스입니다.')
        }

      },
      reLoad(){
            firebase.database().ref('users/').child(this.$store.state.fireUser.uid)
            .get()
            .then((snp)=>{
                this.$store.commit("setUserState", snp.val());
            })
      }
    },
    props:{
      detailLink:String,
      classInfo:Object
    }
  }
</script>
<style scoped>
.dc{
    width: 30%;
    height: 15%;
    background-color: #0091EA;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border:2px solid white;
    color:white;
    font-weight: bolder;
}
.class-card{
  user-select:none;
}
</style>