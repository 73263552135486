<template>
    <v-card class="ma-3 pa-5" flat rounded="1" >
        <v-card-action >
            <v-progress-circular
            :value="80"
            width="3"
            color="deep-purple lighten-2"
            class="float-left ml-3 mt-2"
            >
            1
            </v-progress-circular>
            {{title}}  
            <v-btn
                class="ml-16 mt-3"
                icon
                @click="show = !show"
            >
                <v-icon class="purple--text lighten-2">{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </v-card-action>
        <div style="margin-left:-130px" class="caption">{{complete}} / {{all}}</div>
        <v-expand-transition><div v-if="show">
            <v-list >
            <v-list-item-group
                v-model="selectedItem"
                class="pa-3 "
                color="purple"
            >
                <v-list-item
                v-for="(item, i) in items"
                :key="i"
                class="pl-n16"
                @click="$store.commit('setSrc',item.src)"
                >
                    {{item.text}}
                </v-list-item>

            </v-list-item-group>
            </v-list>
        </div>
        </v-expand-transition>
    </v-card>
</template>

<script>
export default {
    props:['title'],
    data(){
        return {
            show: false,
            complete:7,
            all:9,
            selectedItem: 0,
            items: [
            {text:'엔트리오 아두이노1',src:"https://player.vimeo.com/video/559121108?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"},
            { text: '엔트리와 아두이노2', src: 'https://player.vimeo.com/video/592115722?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&amp;h=7036d37e32' },
            { text: '엔트리와 아두이노3', src: 'https://player.vimeo.com/video/644403287?h=48570a03c1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },
            { text: '엔트리와 아두이노4', src: 'https://player.vimeo.com/video/649501692?h=ca5c4b21da&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479' },

            ],
        }
    },
    methods:{
        setSrc(a){
            alert(a)
        }
    }
}
</script> 