<template>
  <v-hover class="class-card">
    <template v-slot:default="{ hover }">
      <v-card
        flat
        outlined
      >
        <v-row>
          <v-col
            cols="6"
          >
            <v-img :src="classInfo.imgSrc" />
          </v-col>
            <v-col
          cols="6"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title-main">엔트리 기초강좌!</v-list-item-title>
                <v-list-item-subtitle class="title-sub">엔트리 설치하기 부터 알고리즘,</v-list-item-subtitle>
                 <v-list-item-subtitle class="title-sub">실사용 예제를 통한 강좌</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="title-main">목차</v-list-item-title>
                <v-list-item-subtitle class="title-sub">1. 코딩과 알고리즘</v-list-item-subtitle>
                <v-list-item-subtitle class="title-sub">2. 엔트리를 알아보고 설치해보자</v-list-item-subtitle>
                <v-list-item-subtitle class="title-sub">3. 엔트리로 자판기를 만들어보자</v-list-item-subtitle>
                <v-list-item-subtitle class="title-sub">4. 숫자 게임을 만들어보자</v-list-item-subtitle>
                <v-list-item-subtitle class="title-sub">5. 요리조리 피하기 게임을 만들어보자</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title-main">강사</v-list-item-title>
                <v-list-item-subtitle class="title-sub">송혜원, 심동현</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        
          <v-fade-transition>
          <div  @click="toGo()">
          <v-overlay 
            v-if="hover"
            absolute
            opacity="0"
            color="primary"
            style="cursor:pointer"
            
          >
          <v-card-text >
            <div class="title ">{{classInfo.content}}</div>
            
          </v-card-text>
          </v-overlay>
          
          </div>
        </v-fade-transition>
      </v-card>
      
    </template>
  </v-hover>

</template>

<script>
  import firebase from 'firebase'
  export default {
    data: () => ({
      overlay: false,

    }),
    methods:{
      formatPrice(value) {
          let val = (value/1)
          return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      toGo(){
      if(this.$store.state.fireUser!==null && this.$store.state.userState.class1===true){
            this.$router.push('/video')
        }else if(this.$store.state.fireUser!==null && this.$store.state.userState.class1===undefined){
            if(confirm("수강신청을 하시겠습니까?")){
            firebase.database().ref('users/'+this.$store.state.fireUser.uid).update({
                class1:false
            })
                alert('수강신청을 완료했습니다.')
                this.reLoad()
            }else{
                alert("수강신청을 취소했습니다.")
            }
        }else if(this.$store.state.fireUser!==null && this.$store.state.userState.class1!==true){
            alert('관리자가 확인중입니다. 다시로그인을 시도해 주세요.')
             this.reLoad()
        }else{
            alert('로그인이 필요한 서비스입니다.')
        }

      },
      reLoad(){
            firebase.database().ref('users/').child(this.$store.state.fireUser.uid)
            .get()
            .then((snp)=>{
                this.$store.commit("setUserState", snp.val());
            })
      }
    },
    props:{
      detailLink:String,
      classInfo:Object
    }
  }
</script>
<style scoped>
.dc{
    width: 30%;
    height: 15%;
    background-color: #0091EA;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border:2px solid white;
    color:white;
    font-weight: bolder;
}
.class-card{
  user-select:none;
}
.title-main{
  color:#0091EA;
  font-size: xx-large;

}
.title-sub{
  font-size: large;
}
</style>