import Vue from 'vue'
import VueRouter from 'vue-router'
import store from'../store/index'
import EduMain from '../views/EduMain'
import EduIndex from '../views/EduIndex'
import VideoMain from '../views/VideoMain'
import VideoMain2 from '../views/VideoMain2'
Vue.use(VueRouter)

const requireAuth = () => (to, from, next) => {
  if (store.state.userState!==null) {
    return next();
  }
  alert('잘못된 접근입니다.')
  next('/');
};


const routes = [
  {
    path:'/',
    component:EduMain,
    children:[
      {
        path:'',
        component:EduIndex
      },
      {
        path:'/intro',
        component:EduIndex
      },
      {
        path:'/video',
        component:VideoMain,
        beforeEnter: requireAuth()
      },
      {
        path:'/video2',
        component:VideoMain2,
        beforeEnter: requireAuth()
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
