<template>
    <v-sheet
      class="mx-auto my-auto"
      elevation="0"
      color="grey"
      max-width="1600"
    >
      <v-slide-group
        v-model="model"
        class="pa-4"
        show-arrows
      >
        <v-slide-item
          v-for="(item, i) in items"
          :key="i"
          v-slot="{  toggle }"
        >
          <v-card
            class="ma-4"
            height="300"
            width="400"
            elevation="0"
            @click="toggle"
          >
          <v-img max-height="450" :src=item.src></v-img>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
</template>

<script>
export default {

    data(){
        return {

            items: [
            { src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/1.png?alt=media&token=f134cad2-46b3-4888-ae64-d2051dc2bdb0' },
            { src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/2.jpg?alt=media&token=c27c2130-f586-47ad-b936-a8ae007a1fde' },
            { src: 'https://firebasestorage.googleapis.com/v0/b/whatslab-67b1d.appspot.com/o/3.png?alt=media&token=bd8d6b30-025e-473b-901c-f6b480b0a4e5' },

            ],
        }
    },
    methods:{
        setSrc(a){
            alert(a)
        }
    }
}
</script> 