<template>
<v-app style="overflow-y:none">
    <v-navigation-drawer
      width="350"
      color="#EEEEEE"
      bottom
      right
      app

    >
    <v-tabs background-color="#EEEEEE" color="purple">
        <v-tab >강의 목록</v-tab>
        
    </v-tabs>
    <v-divider></v-divider>
    <ClassList :title="'엔트리 강의'" />
      <v-fab-transition>
    <v-btn
      color="purple"
      dark
      class="mb-16"
      absolute
      elevation="0"
      bottom
      right
      fab
    >
      <v-icon>mdi-note</v-icon>
    </v-btn>
  </v-fab-transition>
    </v-navigation-drawer>

    <v-app-bar elevation="1" color="white"  app >
      <v-icon class="mr-6" color="#CE93D8" x-large style="cursor:pointer" @click="goToMain">mdi-arrow-left-circle</v-icon>
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- <div style="color:#bbbbbb" class="d-md-flex d-none text-caption mr-5" >수강률 {{rate}}%</div>
      <div style="color:#bbbbbb" class="d-md-flex d-none text-caption mr-5">수강시간 {{myCours}}</div>
      <div style="color:#bbbbbb" class="d-md-flex d-none text-caption ">강의시간 {{cours}}</div> -->
    </v-app-bar>

    <v-main >
    <v-container fluid>
      <v-layout class="justify-center align-center">
        <iframe style="width:85vw; height: 85vh"  :src="current" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </v-layout>

    </v-container> 
      <!-- <div class="d-md-none d-lg-flex">
        <v-tabs grow color="purple" slider-color="rgba(0,0,0,0)">
            <v-tab><v-icon class="mr-3">mdi-clipboard-text-outline</v-icon>강의소개</v-tab>
            <v-tab><v-icon class="mr-3">mdi-account-circle-outline</v-icon>강사소개</v-tab>
            <v-tab><v-icon class="mr-3">mdi-file-download-outline</v-icon>강의자료</v-tab>
            <v-tab><v-icon class="mr-3">mdi-comment-alert-outline</v-icon>공지사항</v-tab>
            <v-tab><v-icon class="mr-3">mdi-chart-line</v-icon>학습진척도</v-tab>
        </v-tabs>
      </div> -->
      <div class="d-none d-sm-flex d-md-none">

      </div>
      
    </v-main>
  </v-app>
</template>

<script>
import ClassList from '../components/ClassList'
export default {
    components:{
    ClassList
    },
    data(){
        return{
            title: '엔트리 기초 강좌',
            rate: 34,
            myCours: '13:44',
            cours:'38:44:33',
            currentSrc:'https://player.vimeo.com/video/536315919?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
            memo:3

        }
    },
    computed:{
      current(){
        return this.$store.state.crruentSrc
      }
    },
    methods:{
      selectLecture(src){
        this.currentSrc = src
      },
      goToMain(){
        this.$router.push('/')
      },
      setSrc(){
        this.$store.commit('setSrc',this.currentSrc)
      }
    },
    mounted(){
      this.setSrc();
    }

}
</script>