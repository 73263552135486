import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    crruentSrc:'https://player.vimeo.com/video/536315919?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    editable:true,
    fireUser:null,
    userState:null,
  },
  mutations: {
    setSrc(state,src){
      state.crruentSrc = src
    },
    setEdit(state,edit){
      state.editable = edit
    },
    setFireUser(state,fireU){
      state.fireUser = fireU
    },
    setUserState(state,snp){
      state.userState = snp
    }
  },
  actions: {
  },
  modules: {
  }
})
